/* eslint-disable no-param-reassign */
import { createApp } from 'vue';
import App from '@/App.vue';
import DKToast from 'vue-dk-toast';

import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from '@/firebaseConfig';
import toastConfig from '@/toastConfig';
import router from '@/router';
import { QueryClient, VUE_QUERY_CLIENT } from 'vue-query';

import '@/assets/styles/scss/main.scss';
import '@/assets/styles/css/tailwind.css';

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(async user => {
	if (!user) {
		localStorage.removeItem('signedInUser');
		return;
	}
	// if (!user.emailVerified) {
	// 	localStorage.removeItem('signedInUser');
	// 	return;
	// }
	/** @type {t.storage.SignedInUser['data']} */
	const signedInUser = {
		id: /** @type {t.UserId} */ (user.uid),
		email: user.email,
		displayName: user.displayName,
		providerData: user.providerData,
		photoURL: user.photoURL
	};
	localStorage.setItem('signedInUser', JSON.stringify(signedInUser));
});

export const queryClient = new QueryClient();
queryClient.mount();

const app = createApp(App)
	.provide(VUE_QUERY_CLIENT, queryClient)
	.use(DKToast, toastConfig)
	.use(router);

app.directive('number', {
	// When the bound element is mounted into the DOM...
	/**
	 * @param {HTMLInputElement} el
	 * @param {import('vue').DirectiveBinding<{min:number, max:number}>} binding
	 */
	mounted(el, binding) {
		el.oninput = e => {
			let numberValue = Number(el.value.replace(/\D/g, ''));
			const options = binding.value;
			if (options?.min) {
				numberValue = Math.max(numberValue, options?.min);
			}
			if (options?.max) {
				numberValue = Math.min(numberValue, options?.max);
			}
			el.value = `${numberValue}`;
		};
	}
});

app.mount('#app');
