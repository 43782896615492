import { createWebHistory, createRouter } from 'vue-router';

const history = createWebHistory();

const router = createRouter({
	history,
	routes: [
		{
			path: '',
			component: () => import('@/layouts/FullPage.vue'),
			meta: {
				isRequiredAuthorize: false
			},
			children: [
				{
					path: '',
					name: 'top',
					component: () => import('@/views/Top.vue')
				},
				{
					path: 'contact-form',
					name: 'contact_form',
					component: () => import('@/views/ContactForm.vue')
				},
				{
					path: 'password-reset',
					name: 'password_reset',
					component: () => import('@/views/password-reset/PasswordReset.vue')
				},
				{
					path: 'platform',
					name: 'platform',
					component: () => import('@/views/Platform.vue')
				},
				{
					path: 'policy',
					name: 'policy',
					component: () => import('@/views/Policy.vue')
				},
				{
					path: 'disclaimer',
					name: 'disclaimer',
					component: () => import('@/views/Disclaimer.vue')
				},
				{
					path: 'term-of-use',
					name: 'term-of-use',
					component: () => import('@/views/TermOfUse.vue')
				},
				{
					path: 'auth-action',
					name: 'auth_action',
					component: () => import('@/views/auth-action/AuthAction.vue')
				},
				{
					path: 'register',
					name: 'register',
					component: () => import('@/views/Register.vue')
				},
				{
					path: 'sign-up-guide',
					name: 'sign_up_guide',
					component: () => import('@/views/SignUpGuide.vue')
				},

				{
					path: '/errors/404',
					name: 'error_404',
					component: () => import('@/views/errors/404.vue')
				},
				{
					path: '/errors/500',
					name: 'error_500',
					component: () => import('@/views/errors/500.vue')
				},
				{
					path: '/:pathMatch(.*)*',
					redirect: '/errors/404'
				}
			]
		},
		{
			path: '',
			component: () => import('@/layouts/Dashboard.vue'),
			meta: {
				isRequiredAuthorize: true
			},
			children: [
				{
					path: '',
					name: 'dashboard',
					redirect: '/puzzle'
				},
				{
					path: '/puzzle',
					name: 'puzzle',
					component: () => import('@/views/Puzzle.vue')
				},
				{
					path: '/notifications',
					name: 'notifications',
					component: () => import('@/views/Notifications/index.vue')
				},
				{
					path: '/favorite',
					name: 'favorite',
					component: () => import('@/views/Favorite.vue')
				},
				{
					path: '/follow-list',
					name: 'follow_list',
					component: () => import('@/views/FollowList.vue')
				},
				{
					path: '/follower-list',
					name: 'follower_list',
					component: () => import('@/views/FollowerList.vue')
				},
				{
					path: '/invitation',
					name: 'invitation',
					component: () => import('@/views/invitation/Invitation.vue')
				},
				{
					path: '/likes',
					name: 'likes',
					component: () => import('@/views/Likes.vue')
				},
				{
					path: '/messages/:messageId',
					name: 'message_detail',
					component: () => import('@/views/messges/MessageDetail.vue')
				},
				{
					path: '/messages',
					name: 'message_list',
					component: () => import('@/views/messges/MessageList.vue')
				},
				{
					path: '/my-page/:userId',
					name: 'my_page',
					component: () => import('@/views/my-page/MyPage.vue')
				},
				{
					path: '/posts/:id',
					name: 'posts_detail',
					component: () => import('@/views/posts/PostDetail.vue')
				},
				{
					path: '/posts/create',
					name: 'posts_create',
					component: () => import('@/views/posts/Create.vue')
				},
				{
					path: '/posts/:id/edit',
					name: 'posts_edit',
					component: () => import('@/views/posts/Edit.vue')
				},
				{
					path: 'setting',
					name: 'setting',
					component: () => import('@/views/settings/Setting.vue')
				},
				{
					path: 'setting/change-password',
					name: 'setting.change_password',
					component: () => import('@/views/settings/SettingChangePassword.vue')
				},
				{
					path: 'user-profile',
					name: 'user_profile',
					component: () => import('@/views/UserProfile.vue')
				},
				{
					path: '/:pathMatch(.*)*',
					redirect: '/errors/404'
				}
			]
		}
	],
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	}
});

router.beforeEach((to, from, next) => {
	const signedInUser = JSON.parse(localStorage.getItem('signedInUser'));

	if (signedInUser === null && to.meta.isRequiredAuthorize) return next({ name: 'top' });

	if (signedInUser !== null && to.name === 'top') return next({ name: 'dashboard' });
	return next();
});

router.afterEach(() => {
	// TODO Add preloader component
	const appLoading = document.getElementById('preloader');

	if (appLoading) {
		appLoading.classList.add('fade-out');

		setTimeout(() => {
			appLoading.style.display = 'none';
		}, 500);
	}
});

export default router;
